import React, { useContext, useState, useCallback } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import {
  Dressing,
  likeDressing,
  unlikeDressing,
} from './client-db/dressing/dressing';
import { AuthContext } from './auth';
import Badge from '@material-ui/core/Badge';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { useHistory } from 'react-router';

export default ({ dressing }: { dressing: Dressing | undefined }) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [hasLiked, setHasLiked] = useState<boolean>(
    !!dressing?.likes?.find((e) => e === user?.uid)
  );

  const handleLikeClick = useCallback(() => {
    if (!user?.uid) {
      history.push('/login');
      return;
    }

    if (!dressing?.id) {
      console.error('Dressing needs to be set');
      return;
    }

    if (!hasLiked) {
      likeDressing(dressing!.id, user!.uid);
      setHasLiked(true);
    } else {
      unlikeDressing(dressing!.id, user!.uid);
      setHasLiked(false);
    }
  }, [dressing, hasLiked, user, history]);

  return (
    <Tooltip title={!user ? 'Log in to like dressings' : ''}>
      <span>
        <IconButton
          aria-label="4 pending messages"
          onClick={() => {
            handleLikeClick();
          }}
        >
          <Badge color="primary">
            <ThumbUpIcon color={hasLiked ? 'primary' : 'disabled'} />
          </Badge>
        </IconButton>
      </span>
    </Tooltip>
  );
};
