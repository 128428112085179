import React, { useCallback, useContext, useMemo } from 'react';
import {
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  CardContent,
  Chip,
} from '@material-ui/core';
import styled from 'styled-components';
import { Dressing } from '../client-db/dressing/dressing';
import { useHistory } from 'react-router-dom';
import LikeButton from '../LikeButton';
import { AuthContext } from '../auth';

export default ({ dressing }: { dressing: Dressing }) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const goToDetails = useCallback(
    (dressingId: string) => {
      history.push(`/dressing/${dressingId}`);
    },
    [history]
  );

  const distinctIngredients = useMemo(() => {
    const set = new Set(dressing.shortIngredients);
    return Array.from(set.values());
  }, [dressing.shortIngredients]);

  return (
    <DressingCard key={dressing.id}>
      <ImageWrapper>
      <DressingCardHeader
        title={dressing.name}
        onClick={() => goToDetails(dressing.id)}
      />
      <DressingImage
        image={dressing.imageUrl || require('../assets/caesar.jpg')}
        title={dressing.name}
        onClick={() => goToDetails(dressing.id)}
      />
      </ImageWrapper>
      {/* <DressingCardContent onClick={() => goToDetails(dressing.id)}>
        {distinctIngredients.map((ing) => (
          <Ingredient label={ing} key={ing} />
        ))}
      </DressingCardContent> */}
      {!!user && <CardActions disableSpacing>
        <LikeButton dressing={dressing} />
      </CardActions>}
    </DressingCard>
  );
};

const ImageWrapper = styled.div`
  position: relative;
`;

const DressingCardHeader = styled(CardHeader)`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  color: white;
  background-color: rgba(0,0,0,0.5);
  width: 238px;
`;

const DressingCardContent = styled(CardContent)`
  cursor: pointer;
  flex-grow: 1;
`;

const DressingCard = styled(Card)`
  width: 270px;
  margin: 30px 10px;
  display: flex;
  flex-direction: column;
`;

const DressingImage = styled(CardMedia)`
  height: 405px;
  cursor: pointer;
`;

const Ingredient = styled(Chip)`
  margin: 4px;
`;
