import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { QuerySnapshot } from '@firebase/firestore-types';

const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const arrayRemove = firebase.firestore.FieldValue.arrayRemove;

export enum AmountType {
  Part = 'PART',
  Ounce = 'OUNCE',
  Gram = 'GRAM',
  Tablespoon = 'TABLESPOON',
  Teaspoon = 'TEASPOON',
  Cup = 'CUP',
  Milliliter = 'MILLILITER',
  // TODO this is the only amount that doesn't go with a quantity.
  // Some = 'SOME',
  Whole = 'WHOLE',
  Pinch = 'PINCH',
}

export interface Dressing {
  id: string;
  name: string;
  ingredients: Ingredient[];
  shortIngredients: string[];
  directions?: string;
  ownerUid: string;
  ownerDisplayName: string;
  createdAt: string;
  updatedAt: string;
  likes?: string[];
  imageUrl?: string;
}

export interface Ingredient {
  name: string;
  amountType: AmountType;
  amount: string;
}

export interface CreateDressingRequest {
  name: string;
  ingredients: Ingredient[];
  ownerUid: string;
  ownerDisplayName?: string;
  directions?: string;
  imageUrl?: string;
}

export async function createDressing(
  createDressingRequest: CreateDressingRequest
): Promise<string | undefined> {
  const timeNow = new Date().toISOString();
  const dressing = {
    ...createDressingRequest,
    shortIngredients: createDressingRequest.ingredients.map((ing) => ing.name),
    createdAt: timeNow,
    updatedAt: timeNow,
    likes: [],
    likeCount: 0,
  };

  // Add optional fields.
  if (createDressingRequest.directions) {
    dressing.directions = createDressingRequest.directions;
  } else {
    delete dressing.directions;
  }

  try {
    const docRef = await firebase
      .firestore()
      .collection('dressings')
      .add(dressing);
    return docRef.id;
  } catch (err) {
    console.error('creating dressing failed', err);
    return;
  }
}

export interface UpdateDressingRequest {
  id: string;
  name: string;
  ingredients: Ingredient[];
  directions?: string;
  imageUrl?: string;
}

export async function updateDressing(
  updateDressingRequest: UpdateDressingRequest
): Promise<string | undefined> {
  const timeNow = new Date().toISOString();
  const dressing = {
    ...updateDressingRequest,
    shortIngredients: updateDressingRequest.ingredients.map((ing) => ing.name),
    updatedAt: timeNow,
  };

  // Add optional fields.
  if (updateDressingRequest.directions) {
    dressing.directions = updateDressingRequest.directions;
  } else {
    delete dressing.directions;
  }

  try {
    await firebase
      .firestore()
      .collection('dressings')
      .doc(dressing.id)
      .update(dressing);
  } catch (err) {
    console.error('creating dressing failed', err);
    return;
  }
}

export async function getPopularDressings(): Promise<Dressing[]> {
  try {
    return await firebase
      .firestore()
      .collection('dressings')
      .orderBy('likeCount', 'desc')
      .limit(12)
      .get()
      .then((querySnapshot: QuerySnapshot) => {
        const dressings: Dressing[] = [];
        querySnapshot.forEach((doc) => {
          dressings.push({ id: doc.id, ...doc.data() } as Dressing);
        });
        return dressings;
      });
  } catch (err) {
    console.error('getting popular dressings failed', err);
    return [];
  }
}

export async function getRecentDressings(): Promise<Dressing[]> {
  try {
    return await firebase
      .firestore()
      .collection('dressings')
      .orderBy('createdAt', 'desc')
      .limit(20)
      .get()
      .then((querySnapshot: QuerySnapshot) => {
        const dressings: Dressing[] = [];
        querySnapshot.forEach((doc) => {
          dressings.push({ id: doc.id, ...doc.data() } as Dressing);
        });
        return dressings;
      });
  } catch (err) {
    console.error('getting recent dressings failed', err);
    return [];
  }
}

export async function getDressing(id: string): Promise<Dressing | undefined> {
  try {
    const doc = await firebase
      .firestore()
      .collection('dressings')
      .doc(id)
      .get();

    if (!doc.data()) throw new Error(`Dressing ${id} not found`);

    const dressing = {
      ...doc.data(),
      id,
    } as Dressing;

    return dressing;
  } catch (err) {
    console.error(err);
    return;
  }
}

export async function deleteDressing(id: string) {
  try {
    await firebase.firestore().collection('dressings').doc(id).delete();
  } catch (err) {
    console.error(err);
    return;
  }
}

export async function getMyDressings(uid: string): Promise<Dressing[]> {
  try {
    return await firebase
      .firestore()
      .collection('dressings')
      .where('ownerUid', '==', uid)
      .get()
      .then((querySnapshot: QuerySnapshot) => {
        const dressings: Dressing[] = [];
        querySnapshot.forEach((doc) => {
          dressings.push({ id: doc.id, ...doc.data() } as Dressing);
        });
        return dressings;
      });
  } catch (err) {
    console.error('getting dressings failed', err);
    return [];
  }
}

export async function getDressingsByIds(ids: string[]): Promise<Dressing[]> {
  try {
    return await firebase
      .firestore()
      .collection('dressings')
      .limit(12)
      .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
      .get()
      .then((querySnapshot: QuerySnapshot) => {
        const dressings: Dressing[] = [];
        querySnapshot.forEach((doc) => {
          dressings.push({ id: doc.id, ...doc.data() } as Dressing);
        });
        return dressings;
      });
  } catch (err) {
    console.error('getting dressings failed', err);
    return [];
  }
}

export async function likeDressing(dressingId: string, userId: string) {
  return await firebase
    .firestore()
    .collection('dressings')
    .doc(dressingId)
    .update({
      likes: arrayUnion(userId),
    });
}

export async function unlikeDressing(dressingId: string, userId: string) {
  return await firebase
    .firestore()
    .collection('dressings')
    .doc(dressingId)
    .update({
      likes: arrayRemove(userId),
    });
}

export type dressingCb = (dressing: Dressing | undefined) => void;

export function registerDressingListener(dressingId: string, cb: dressingCb) {
  return firebase
    .firestore()
    .collection('dressings')
    .doc(dressingId)
    .onSnapshot((doc) => {
      if (!doc?.data()) {
        cb(undefined);
      } else {
        cb({ id: doc.id, ...doc.data() } as Dressing);
      }
    });
}
