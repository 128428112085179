import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Container, Typography, Button, TextField } from '@material-ui/core';
import AppBar from '../AppBar';
import styled from 'styled-components';
import { AuthContext } from '../auth';
import {
  registerMyUserListener,
  DbUser,
  setDisplayName,
} from '../client-db/users/users';
import Loading from '../Loading';
import LogInRequired from '../auth/LogInRequired';
import Footer from '../Footer';

export default () => {
  const { initialized, user } = useContext(AuthContext);
  const [persistedDisplayName, setPersistedDisplayName] = useState<string>('');
  const [tempDisplayName, setTempDisplayName] = useState<string>('');

  const updateDisplayName = useCallback(async () => {
    if (!user) {
      console.error('Must be logged in to update display name');
      return;
    }

    setDisplayName(user, tempDisplayName);
  }, [user, tempDisplayName]);

  useEffect(() => {
    let unregister;
    if (initialized && user && user.uid) {
      if (user.displayName) {
        setPersistedDisplayName(user.displayName);
        setTempDisplayName(user.displayName);
      }

      unregister = registerMyUserListener(user.uid, (user: DbUser) => {
        if (user?.displayName) {
          setPersistedDisplayName(user.displayName);
        }
      });
    }
    return unregister;
  }, [initialized, user, setTempDisplayName]);

  const buttonDisabled = useMemo(() => {
    if (!initialized || !user || !tempDisplayName) {
      return true;
    }
    return tempDisplayName === persistedDisplayName;
  }, [initialized, persistedDisplayName, tempDisplayName, user]);

  return (
    <Fragment>
      <AppBar />
      {!initialized && <Loading />}
      {initialized && (
        <StyledContainer>
          {!user && <LogInRequired route="/settings" />}
          {!!user && initialized && (
            <Fragment>
              <HeadingContainer>
                <Typography variant="h5">Settings</Typography>
              </HeadingContainer>
              <FieldContainer>
                <TextField
                  autoFocus
                  value={tempDisplayName}
                  label="Display name"
                  variant="outlined"
                  onChange={(event) => {
                    setTempDisplayName(event.target.value);
                  }}
                />
              </FieldContainer>
              <FieldContainer>
                <Button
                  onClick={() => updateDisplayName()}
                  disabled={buttonDisabled}
                  variant="contained"
                  color="primary"
                >
                  Update display name
                </Button>
              </FieldContainer>
            </Fragment>
          )}
        </StyledContainer>
      )}
      <Footer />
    </Fragment>
  );
};

const StyledContainer = styled(Container)`
  margin-bottom: 60px;
`;

const FieldContainer = styled.div`
  margin-bottom: 20px;
`;

const HeadingContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
`;
