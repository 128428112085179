import firebase from 'firebase/app';
import 'firebase/auth';

export default () => {
  var provider = new firebase.auth.GoogleAuthProvider();
  return firebase.auth().signInWithRedirect(provider);
};

export const signInWithPopup = () => {
  return firebase
    .auth()
    .signInWithPopup(new firebase.auth.GoogleAuthProvider());
};
