import React, { Fragment, useContext } from 'react';
import AppBar from '../AppBar';
import LogInRequired from '../auth/LogInRequired';
import { AuthContext } from '../auth';
import { useHistory } from 'react-router';

export default () => {
  const { user } = useContext(AuthContext);
  const history = useHistory();

  if (!!user) {
    history.push('/');
  }

  return (
    <Fragment>
      <AppBar />
      <LogInRequired route="/" />
    </Fragment>
  );
};
