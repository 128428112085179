import { createMuiTheme } from '@material-ui/core';
import colors from './colors';

export default createMuiTheme({
  palette: {
    primary: {
      light: colors.emerald,
      main: colors.greenHaze,
      dark: colors.funGreen,
      contrastText: colors.white,
    },
  },
  typography: {
    fontFamily: 'Nunito Sans',
    h5: {
      fontFamily: 'Merriweather',
    },
    h3: {
      fontFamily: 'Merriweather',
    },
  },
});
