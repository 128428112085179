import AuthContext from './AuthContext';
import React, { useState, useEffect, ReactNode } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { User } from '@firebase/auth-types';
import { initFirebase } from '../init/init';
import { updateUser } from '../client-db/users/users';

export default ({ children }: { children: ReactNode[] }) => {
  const [user, setUser] = useState<User | null>(null);
  const [dbInitialized, setDbInitialized] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (!dbInitialized) {
      initFirebase();
      setDbInitialized(true);
    }
  }, [setUser, setDbInitialized, dbInitialized]);

  useEffect(() => {
    if (dbInitialized) {
      const unregister = firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setUser(user);
          updateUser(user);
        } else {
          setUser(null);
        }
        setInitialized(true);
      });

      return unregister;
    }
  }, [dbInitialized]);

  return (
    <AuthContext.Provider value={{ user, initialized }}>
      {children}
    </AuthContext.Provider>
  );
};
