import React, { useContext, useCallback } from 'react';
import { AuthContext } from '../auth';
import { useHistory } from 'react-router-dom';
import CreateDressingPage from './CreateDressingPage';

export default () => {
  const { user, initialized } = useContext(AuthContext);
  const history = useHistory();

  const redirectOnCreate = useCallback(
    (id: string) => {
      history.push(`/dressing/${id}`);
    },
    [history]
  );

  return (
    <CreateDressingPage
      user={user}
      initialized={initialized}
      redirectOnCreate={redirectOnCreate}
    />
  );
};
