import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { User } from 'firebase';

export interface DbUser {
  uid: string;
  email: string;
  photoUrl: string;
  displayName: string;
}

export async function updateUser(user: User) {
  const dbUser = {
    uid: user.uid,
    email: user.email,
    photoUrl: user.photoURL,
    displayName: user.displayName,
  };

  try {
    await firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .set(dbUser, { merge: true });
  } catch (err) {
    console.error('setting user failed', err);
    return;
  }
}

export type userCb = (user: DbUser) => void;
export function registerMyUserListener(uid: string, cb: userCb) {
  return firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .onSnapshot((doc) => {
      cb(doc.data() as DbUser);
    });
}

export async function setDisplayName(user: User, displayName: string) {
  try {
    await user.updateProfile({ displayName });
    firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .update({ displayName });
  } catch (err) {
    console.error('Updating display name error', err);
    return;
  }
}
