import React, { useContext, useCallback } from 'react';
import { AuthContext } from '../auth';
import { useHistory, useParams } from 'react-router-dom';
import EditDressingPage from './EditDressingPage';

export default () => {
  const { user, initialized } = useContext(AuthContext);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const redirectOnCreate = useCallback(
    (id: string) => {
      history.push(`/dressing/${id}`);
    },
    [history]
  );

  return (
    <EditDressingPage
      id={id}
      user={user}
      initialized={initialized}
      redirectOnUpdate={redirectOnCreate}
    />
  );
};
