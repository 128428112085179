/** Names from http://chir.ag/projects/name-that-color */
export default {
  boulderGray: '#777777',
  dustyGray: '#999999',
  altoGray: '#dddddd',

  emerald: '#41c067',
  greenHaze: '#009945',
  funGreen: '#00662e',

  white: '#ffffff',

  appBarBackground:
    'linear-gradient(38deg, rgba(21,54,0,1) 0%, rgba(0,102,46,1) 58%, rgba(183,193,2,1) 100%)',
};
