import React from 'react';
import { Container, Typography } from '@material-ui/core';
import styled from 'styled-components';
import colors from './styles/colors';

export default () => {
  return (
    <FooterBackground>
      <Container>
        <Typography variant="caption">
          <LightText>Made with &hearts; in NYC by Carly & JJ</LightText>
        </Typography>
      </Container>
    </FooterBackground>
  );
};

const LightText = styled.span`
  color: ${colors.dustyGray};
`;

const FooterBackground = styled.div`
  border-top: 1px solid ${colors.altoGray};
  padding-top: 20px;
  padding-bottom: 20px;
`;
