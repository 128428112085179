import { useParams } from 'react-router-dom';
import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import AppBar from './AppBar';
import styled from 'styled-components';
import {
  registerDressingListener,
  Dressing,
  deleteDressing,
} from './client-db/dressing/dressing';
import { AuthContext } from './auth';
import Footer from './Footer';
import LikeButton from './LikeButton';
import { useHistory } from 'react-router-dom';
import Loading from './Loading';
import colors from './styles/colors';

export default () => {
  const history = useHistory();
  const { user, initialized } = useContext(AuthContext);
  let { id } = useParams<{ id: string }>();
  const [dressing, setDressing] = useState<Dressing | undefined>();
  const [dressingLoaded, setDressingLoaded] = useState<boolean>(false);
  const [youLiked, setYouLiked] = useState<boolean>(false);

  const deleteDress = useCallback(async () => {
    if (!user) {
      console.error('You need to be logged in to delete your dressing');
      return;
    }

    if (!dressing) {
      console.error('Dressing has not loaded');
      return;
    }

    const confirmDelete = window.confirm(
      'Are you sure you want to delete this dressing?'
    );

    if (confirmDelete) {
      deleteDressing(dressing.id);
      history.push('/');
    }
  }, [user, dressing, history]);

  useEffect(() => {
    let unregister;
    if (initialized && id) {
      unregister = registerDressingListener(
        id,
        (dress: Dressing | undefined) => {
          setDressingLoaded(true);
          setDressing(dress);
          setYouLiked(!!dress?.likes?.find((e) => e === user?.uid));
        }
      );
    }
    return unregister;
  }, [initialized, id, setDressing, setDressingLoaded, user, setYouLiked]);

  const shouldShowLoading = !initialized || !dressingLoaded;
  const shouldShowDressing = !!(initialized && dressing);
  const shouldShowDressingNotFound = dressingLoaded && !dressing;
  const shouldShowName = !!dressing?.ownerDisplayName;
  return (
    <Fragment>
      <AppBar />
      {shouldShowLoading && <Loading />}
      <StyledContainer>
        {shouldShowDressingNotFound && (
          <DressingNotFoundContainer variant="h3">
            Dressing not found
          </DressingNotFoundContainer>
        )}
        {shouldShowDressing && (
          <DressingContainer>
            <ImageContainer>
              <DressingImage
                src={dressing?.imageUrl || require('./assets/caesar.jpg')}
                alt="Caesar dressing"
              />
            </ImageContainer>
            <InfoContainer>
              <TitleContainer>
                <Typography variant="h3">{dressing!.name}</Typography>
              </TitleContainer>
              <AuthorContainer>
                { shouldShowName && (<Typography variant="body1">
                  By {dressing?.ownerDisplayName}
                </Typography>)}
              </AuthorContainer>
              <ButtonAndCaption>
                <LikeButton dressing={dressing} />
                <Caption>
                  {youLiked && <Typography>Liked</Typography>}
                </Caption>
              </ButtonAndCaption>
              <Header>
                <Typography variant="h5">Ingredients</Typography>
              </Header>
              <IngredientList>
                {dressing?.ingredients.map((ing) => (
                  <li key={`${ing.amount}-${ing.amountType}-${ing.name}`}>
                    <Typography variant="body1">
                      {` ${ing.amount} ${ing.amountType} ${ing.name}`}
                    </Typography>
                  </li>
                ))}
              </IngredientList>
              {dressing?.directions && (
                <Fragment>
                  <Header>
                    <Typography variant="h5">Directions</Typography>
                  </Header>
                  <TypographyWithNewLines variant="body1">
                    {dressing?.directions}
                  </TypographyWithNewLines>
                </Fragment>
              )}
              {!!user?.uid &&
                !!dressing?.ownerUid &&
                user?.uid === dressing?.ownerUid && (
                  <DeleteContainer>
                    <Button
                      onClick={() => deleteDress()}
                      variant="outlined"
                      color="secondary"
                    >
                      Delete dressing
                    </Button>
                  </DeleteContainer>
                )}
            </InfoContainer>
          </DressingContainer>
        )}
      </StyledContainer>
      <Footer />
    </Fragment>
  );
};

const AuthorContainer = styled.div`
  margin-bottom: 10px;
  color: ${colors.boulderGray};
`;

const ButtonAndCaption = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`;

const DressingImage = styled.img`
  width: 100%;
  max-width: 600px;
`;

const TitleContainer = styled.div`
  margin-bottom: 4px;
`;

const DressingContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 40px;
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 20px;
`;

const IngredientList = styled.ul`
  line-height: 2rem;
`;

const Caption = styled.span`
  margin-left: 10px;
`;

const ImageContainer = styled.div`
  padding: 20px;
`;

const StyledContainer = styled(Container)`
  padding-bottom: 60px;
`;

const TypographyWithNewLines = styled(Typography)`
  white-space: pre-line;
`;

const DressingNotFoundContainer = styled(Typography)`
  padding-top: 60px;
`;

const DeleteContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 40px;
`;
