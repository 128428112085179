import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';

export const firebaseConfig = {
  apiKey: 'AIzaSyCLZ2jDzGXFqh7arqv9OajPi5SwC4C58vo',
  authDomain: 'salad-dressing.firebaseapp.com',
  databaseURL: 'https://salad-dressing.firebaseio.com',
  projectId: 'salad-dressing',
  storageBucket: 'salad-dressing.appspot.com',
  messagingSenderId: '998233923050',
  appId: '1:998233923050:web:1b1d32f81edba1a0b66693',
  measurementId: 'G-FV3L3GSQS7',
};

export function initFirebase() {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings({ experimentalForceLongPolling: true });
  firebase.analytics();
}
