import React, { Fragment, useRef, useState, useCallback, useEffect } from 'react';
import {
  Typography,
  Button,
  Container,
  TextField,
  Chip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
} from '@material-ui/core';
import AppBar from '../AppBar';
import {
  UpdateDressingRequest,
  AmountType,
  Ingredient,
  Dressing,
  registerDressingListener,
  updateDressing,
} from '../client-db/dressing/dressing';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import Footer from '../Footer';
import { User } from 'firebase';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/app';
import 'firebase/storage';
import Loading from '../Loading';
import LogInRequired from '../auth/LogInRequired';

export default ({
  id,
  user,
  initialized,
  redirectOnUpdate,
}: {
  id: string;
  user: User | null;
  initialized: boolean;
  redirectOnUpdate: (id: string) => void;
}) => {
  const [dressing, setDressing] = useState<Dressing | undefined>();
  const [dressingLoaded, setDressingLoaded] = useState<boolean>(false);
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [newIngredientName, setNewIngredientName] = useState<string>('');
  const [newIngredientAmount, setNewIngredientAmount] = useState<string>('');
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(
    false
  );
  const [newIngredientAmountType, setNewIngredientAmountType] = useState<any>(
    ''
  );
  const [title, setTitle] = useState<string>('');
  const [directions, setDirections] = useState<string | undefined>();
  const amountInputRef = useRef<HTMLElement>();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [imageFile, setImageFile] = useState<string | undefined>();

  const displayPreviewImage = useCallback(
    (file: File) => {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImageFile(String(reader.result))
      );
      reader.readAsDataURL(file);
    },
    [setImageFile]
  );

  const imageUpload = useCallback(
    async (file: File) => {
      displayPreviewImage(file);

      const type = file.type.split('/')[1];
      const ref = firebase.storage().ref(`/images/${uuidv4()}.${type}`);
      await ref.put(file);
      const downloadUrl = await ref.getDownloadURL();
      setImageUrl(downloadUrl);
    },
    [displayPreviewImage]
  );

  useEffect(() => {
    let unregister;
    if (initialized && id) {
      unregister = registerDressingListener(
        id,
        (dress: Dressing | undefined) => {
          setDressing(dress);
          setTitle(dress?.name ?? '');
          setIngredients(dress?.ingredients ?? []);
          setDirections(dress?.directions ?? '');
          setImageUrl(dress?.imageUrl ?? '');
          setDressingLoaded(true);
        }
      );
    }
    return unregister;
  }, [initialized, id, setTitle, setIngredients, setDirections]);

  const update = useCallback(async () => {
    if (!user) {
      return;
    }

    const dressing: UpdateDressingRequest = {
      id,
      name: title,
      ingredients,
      directions,
      imageUrl,
    };

    setUpdateButtonDisabled(true);
    try {
      await updateDressing(dressing);
      redirectOnUpdate(id);
    } catch (error) {
      console.error('updating dressing failed', error)
    }
    setUpdateButtonDisabled(false);
  }, [
    user,
    title,
    ingredients,
    directions,
    redirectOnUpdate,
    imageUrl,
    id
  ]);

  const deleteIngredient = useCallback(
    (ingredient: Ingredient) => {
      const indexToDelete = ingredients.indexOf(ingredient);
      const newIngredients = [...ingredients];
      newIngredients.splice(indexToDelete, 1);

      setIngredients(newIngredients);
    },
    [ingredients]
  );

  const addFullIngredient = useCallback(() => {
    if (
      !newIngredientAmount ||
      !newIngredientName ||
      !newIngredientAmountType
    ) {
      return;
    }

    setIngredients([
      ...ingredients,
      {
        name: newIngredientName,
        amountType: newIngredientAmountType,
        amount: newIngredientAmount,
      },
    ]);
    setNewIngredientAmountType('');
    setNewIngredientName('');
    setNewIngredientAmount('');
    amountInputRef?.current?.focus();
  }, [
    newIngredientAmount,
    newIngredientAmountType,
    newIngredientName,
    setIngredients,
    setNewIngredientAmount,
    setNewIngredientName,
    setNewIngredientAmountType,
    ingredients,
  ]);

  return (
    <Fragment>
      <AppBar />
      {!initialized && <Loading />}
      <StyledContainer>
        <BackgroundMuter>
          {!user && initialized && <LogInRequired route="/create" />}
          {!!user && dressingLoaded && (
            <Fragment>
              <TitleContainer>
                <Typography variant="h5">
                  Contribute to the Oil & Vinegar Community
                </Typography>
              </TitleContainer>
              <SubtitleContainer>
                <Typography variant="body1">
                  Dressings you add here will be listed for people to browse
                </Typography>
              </SubtitleContainer>

              <FormContainer>
                <FieldContainer>
                  <TextField
                    defaultValue={title}
                    autoFocus
                    label="Dressing Title"
                    variant="outlined"
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                  />
                </FieldContainer>
                <FormSectionLabel>
                  <Typography variant="h5">Add ingredients</Typography>
                </FormSectionLabel>
                <IngredientContainer>
                  <AmountInputContainer>
                    <TextField
                      inputRef={amountInputRef}
                      label="Amount"
                      variant="outlined"
                      value={newIngredientAmount}
                      onChange={(event) => {
                        setNewIngredientAmount(event.target.value);
                      }}
                    />
                  </AmountInputContainer>
                  <AmountTypeContainer>
                    <AmountTypeFormControl variant="outlined">
                      <InputLabel id="amount-type-label">
                        Amount Type
                      </InputLabel>
                      <Select
                        value={newIngredientAmountType}
                        onChange={(event) => {
                          setNewIngredientAmountType(event.target.value);
                        }}
                        labelId="amount-type-label"
                        label="Amount Type"
                      >
                        {Object.keys(AmountType).map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </AmountTypeFormControl>
                  </AmountTypeContainer>

                  <IngredientNameInput>
                    <TextField
                      label="Ingredient name"
                      variant="outlined"
                      value={newIngredientName}
                      onChange={(event) => {
                        setNewIngredientName(event.target.value);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          addFullIngredient();
                        }
                      }}
                    />
                  </IngredientNameInput>
                  <FieldContainer>
                    <IconButton
                      onClick={() => addFullIngredient()}
                      color="primary"
                    >
                      <AddIcon color="primary" />
                    </IconButton>
                  </FieldContainer>
                </IngredientContainer>

                <FieldContainer>
                  <IngredientsContainer>
                    {ingredients.map((ing) => (
                      <div key={`${ing.amount}-${ing.amountType}-${ing.name}`}>
                        <IngredientChip
                          label={`${ing.amount} ${ing.amountType} ${ing.name}`}
                          onDelete={() => deleteIngredient(ing)}
                        />
                      </div>
                    ))}
                  </IngredientsContainer>
                </FieldContainer>
                <FormSectionLabel>
                  <Typography variant="h5">Add directions</Typography>
                </FormSectionLabel>
                <FieldContainer>
                  <TextField
                    onChange={(event) => {
                      setDirections(event.target.value);
                    }}
                    fullWidth
                    label="Directions"
                    multiline
                    rows={4}
                    placeholder="Enter your directions ..."
                    variant="outlined"
                    defaultValue={directions}
                  />
                </FieldContainer>

                <FormSectionLabel>
                  <Typography variant="h5">Add a photo</Typography>
                </FormSectionLabel>
                <FieldContainer>
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: 'none' }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        if (
                          event &&
                          event.target &&
                          event.target.files &&
                          event.target.files[0]
                        ) {
                          imageUpload(event.target.files[0]);
                        }
                      }}
                    />
                    <Button color="primary" variant="outlined" component="span">
                      Upload Photo
                    </Button>
                  </label>
                </FieldContainer>
                <div>
                  <DressingImage
                    src={imageFile || dressing?.imageUrl || require('../assets/placeholder.png')}
                    alt="dressing"
                  />
                </div>
                <FieldContainer>
                  <SubmitButtonContainer>
                    <Button
                      onClick={() => update()}
                      disabled={updateButtonDisabled}
                      variant="contained"
                      color="primary"
                    >
                      Update Dressing
                    </Button>
                  </SubmitButtonContainer>
                </FieldContainer>
              </FormContainer>
            </Fragment>
          )}
        </BackgroundMuter>
      </StyledContainer>
      <Footer />
    </Fragment>
  );
};

const TitleContainer = styled.div`
  margin-top: 40px;
`;

const IngredientsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BackgroundMuter = styled.div`
  flex: 1;
  background-color: rgba(255, 255, 255, 0.7);
  padding-bottom: 60px;
`;

const StyledContainer = styled(Container)`
  margin-top: 20px;
  flex: 1;
  @media (min-width: 768px) {
    background-image: url('./carrots.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
  }
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const FieldContainer = styled.div`
  margin-bottom: 20px;
`;

const IngredientChip = styled(Chip)`
  margin: 4px;
`;

const IngredientContainer = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
`;

const IngredientNameInput = styled(FieldContainer)`
  margin-right: 12px;
`;

const AmountTypeFormControl = styled(FormControl)`
  width: 160px;
`;

const AmountTypeContainer = styled(FieldContainer)`
  margin-right: 12px;
`;

const AmountInputContainer = styled(FieldContainer)`
  width: 100px;
  margin-right: 12px;
`;

const FormSectionLabel = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const SubtitleContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SubmitButtonContainer = styled.div`
  margin-top: 30px;
`;

const DressingImage = styled.img`
  max-width: 500px;
`;
