import React, { useState, Fragment } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import signIn from './signIn';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

export default function LoginButton() {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="profile"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            signIn();
          }}
        >
          Login
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
