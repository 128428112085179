import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './styles/globalStyles';
import theme from './styles/theme';
import { AuthProvider } from './auth';
import ListDressingsPage from './ListDressings/ListDressings';
import EditDressing from './EditDressing';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CreateDressing from './CreateDressing';
import DressingPage from './DressingPage';
import MyDressingsPage from './MyDressings/MyDressings';
import SettingsPage from './Settings/Settings';
import NotFound from './common/404';
import Login from './Login/Login';

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <GlobalStyles />
        <Router>
          <Switch>
            <Route path="/dressing/:id">
              <DressingPage />
            </Route>
            <Route path="/edit/:id">
              <EditDressing />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/create">
              <CreateDressing />
            </Route>
            <Route path="/my-dressings">
              <MyDressingsPage />
            </Route>
            <Route path="/settings">
              <SettingsPage />
            </Route>
            <Route exact path="/">
              <ListDressingsPage />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};
