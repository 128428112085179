import React, { useCallback, Fragment } from 'react';
import AppBar from '../AppBar';
import {
  Container,
  CardContent,
  Typography,
  Button,
  Card,
} from '@material-ui/core';
import styled from 'styled-components';
import Footer from '../Footer';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
  const history = useHistory();

  const gotoPopular = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <Fragment>
      <AppBar />
      <Container>
        <CardWrapper>
          <StyledCard>
            <CardContent>
              <StyledTypography variant="h5">
                404 Page not found
              </StyledTypography>
              <Button
                onClick={() => {
                  gotoPopular();
                }}
                variant="contained"
                color="primary"
              >
                Browse popular
              </Button>
            </CardContent>
          </StyledCard>
        </CardWrapper>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default NotFound;

const StyledCard = styled(Card)`
  max-width: 300px;
  text-align: center;
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 50px;
`;

const CardWrapper = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 40px;
  margin-bottom: 60px;
`;
