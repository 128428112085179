import React, { useCallback } from 'react';
import {
  Container,
  Typography,
  Card,
  Button,
  CardContent,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { signInWithPopup } from './signIn';
import styled from 'styled-components';

export default ({ route }: { route: string }) => {
  const history = useHistory();

  const login = useCallback(() => {
    signInWithPopup().then(() => {
      history.push(route);
    });
  }, [history, route]);

  return (
    <Container>
      <CardWrapper>
        <StyledCard>
          <CardContent>
            <Typography variant="h5">Join the Community</Typography>
            <BodyWrapper>
              <Typography variant="body1">
                Login or register to create dressings and manage your profile.
              </Typography>
            </BodyWrapper>
            <Button onClick={() => login()} variant="contained" color="primary">
              Login or Register
            </Button>
          </CardContent>
        </StyledCard>
      </CardWrapper>
    </Container>
  );
};

const BodyWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const StyledCard = styled(Card)`
  max-width: 300px;
  text-align: center;
`;

const CardWrapper = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 40px;
  margin-bottom: 60px;
`;
