import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  // useRef,
  // TODO use for throttle
  useCallback,
} from 'react';
import { Container, Typography, TextField } from '@material-ui/core';
import AppBar from '../AppBar';
import styled from 'styled-components';
import {
  getRecentDressings,
  Dressing,
  getDressingsByIds,
} from '../client-db/dressing/dressing';
import { AuthContext } from '../auth';
import Footer from '../Footer';
import DressingCard from './DressingCard';
import algoliasearch from 'algoliasearch';
// TODO throttle the search
// import { throttle } from 'throttle-debounce';

const ALGOLIA_APP_ID = 'IMXKMWTQIR';
const ALGOLIA_SEARCH_KEY = '9472f23211fb2707f3ad1dbc0e5164b3';

const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);
const index = client.initIndex('dressings');

export default () => {
  const { initialized } = useContext(AuthContext);
  const [dressings, setDressings] = useState<Dressing[]>([]);

  const loadPopularDressings = useCallback(async () => {
    const tempDresses = await getRecentDressings();
    setDressings(tempDresses);
  }, [setDressings]);

  const search = useCallback(
    async (searchQuery: string) => {
      if (!initialized) return;

      if (!searchQuery) {
        loadPopularDressings();
        return;
      }

      const response = await index.search(searchQuery);
      const hitIds = response.hits.map((hit) => hit.objectID);
      const tempDresses = await getDressingsByIds(hitIds);
      setDressings(tempDresses);
    },
    [initialized, loadPopularDressings]
  );

  useEffect(() => {
    if (!initialized) return;

    loadPopularDressings();
  }, [initialized, loadPopularDressings]);

  return (
    <Fragment>
      <AppBar />
      <Container>
        <Hero>
          <HeroContent>
            <Typography variant="h3">Add a spark to your salad</Typography>
            <Subtitle>
              <Typography variant="subtitle1">
                Making your own salad dressing makes all the difference
              </Typography>
            </Subtitle>
            <SearchContainer>
              <SearchBar
                onChange={(event) => {
                  search(event.target.value);
                }}
                label="Try searching for 'caesar' or 'lemon'"
                variant="outlined"
                fullWidth
              />
            </SearchContainer>
          </HeroContent>
        </Hero>
        <DressingPageContainer>
          {dressings.map((dressing) => (
            <DressingCard key={dressing.id} dressing={dressing} />
          ))}
        </DressingPageContainer>
      </Container>
      <Footer />
    </Fragment>
  );
};

const HeroContent = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  padding-top: 100px;
  padding-bottom: 60px;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Subtitle = styled.div`
  margin-top: 14px;
  margin-bottom: 28px;
`;

const SearchBar = styled(TextField)`
  flex: 0.75;
  @media (max-width: 768px) {
    flex: 1;
  }
`;

const Hero = styled.div`
  text-align: center;
  @media (min-width: 768px) {
    background-image: url('./lettuce.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }
`;

const DressingPageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 60px;
`;
