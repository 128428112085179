import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { User } from '@firebase/auth-types';
import styled from 'styled-components';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { useState } from 'react';
import signOut from './signOut';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default ({ user }: { user: User }) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  return (
    <Container>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="profile"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        {!!user.photoURL && <ProfilePhoto src={user.photoURL} />}
        {!user.photoURL && <AccountCircleIcon />}
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem component={Link} to="/my-dressings">
          My Dressings
        </MenuItem>
        <MenuItem component={Link} to="/settings">
          Settings
        </MenuItem>
        {!!user && (
          <MenuItem
            onClick={() => {
              signOut(history);
            }}
          >
            Logout
          </MenuItem>
        )}
      </Menu>
    </Container>
  );
};

const Container = styled.span`
  margin-left: 10px;
`;

const ProfilePhoto = styled.img`
  border-radius: 15px;
  height: 30px;
`;
