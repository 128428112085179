import firebase from 'firebase/app';
import 'firebase/auth';
import { History } from 'history';

export default async (history: History) => {
  try {
    await firebase.auth().signOut();
    history.push('/');
  } catch (err) {
    console.error('Error while logging out', err);
  }
};
