import React, { useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import logo from './assets/logo.png';
import styled from 'styled-components';
import { LoginButton, ProfileButton, AuthContext } from './auth';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import colors from './styles/colors';

export default () => {
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);

  return (
    <AppBar
      position="static"
      style={{
        background: colors.appBarBackground,
      }}
    >
      <Container>
        <Toolbar>
          <LogoContainer>
            <Button component={Link} to="/" color="inherit">
              <Logo src={logo} />
              <LogoText>oil & vinegar</LogoText>
            </Button>
          </LogoContainer>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="profile"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="more-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            <MenuItem component={Link} to="/create">
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>Create</ListItemText>
            </MenuItem>

            <MenuItem component={Link} to="/">
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText>Find</ListItemText>
            </MenuItem>
          </Menu>
          {!!user && <ProfileButton user={user} />}
          {!user && <LoginButton />}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const Logo = styled.img`
  margin-right: 10px;
  height: 35px;
`;

const LogoText = styled.span`
  font-family: 'Fredericka the Great';
  font-size: 24px;
  text-transform: none;
`;

const LogoContainer = styled.span`
  flex-grow: 1;
  justify-content: flex-start;
  display: flex;
  align-items: center;
`;
