import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Container, Typography } from '@material-ui/core';
import AppBar from '../AppBar';
import styled from 'styled-components';
import { getMyDressings, Dressing } from '../client-db/dressing/dressing';
import { AuthContext } from '../auth';
import Footer from '../Footer';
import DressingCard from '../ListDressings/DressingCard';
import Loading from '../Loading';
import LogInRequired from '../auth/LogInRequired';

export default () => {
  const { initialized, user } = useContext(AuthContext);
  const [dressings, setDressings] = useState<Dressing[]>([]);

  useEffect(() => {
    async function loadDressings(uid: string) {
      const dressings = await getMyDressings(uid);
      setDressings(dressings);
    }
    if (initialized && user?.uid) {
      loadDressings(user.uid);
    }
  }, [initialized, user]);

  return (
    <Fragment>
      <AppBar />
      <Container>
        {!initialized && <Loading />}
        {!user && initialized && <LogInRequired route="/my-dressings" />}
        {!!user && initialized && (
          <Fragment>
            <Hero>
              <HeroContent>
                <Typography variant="h3">Dressings you've added</Typography>
                <Subtitle>
                  <Typography variant="subtitle1">
                    Great job! Pat yourself on the back.
                  </Typography>
                </Subtitle>
              </HeroContent>
            </Hero>
            <DressingPageContainer>
              {dressings.map((dressing) => (
                <DressingCard key={dressing.id} dressing={dressing} />
              ))}
            </DressingPageContainer>
          </Fragment>
        )}
      </Container>
      <Footer />
    </Fragment>
  );
};

const HeroContent = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  padding-top: 100px;
  padding-bottom: 60px;
`;

const Subtitle = styled.div`
  margin-top: 14px;
  margin-bottom: 28px;
`;

const Hero = styled.div`
  text-align: center;
  @media (min-width: 768px) {
    background-image: url('./lettuce.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }
`;

const DressingPageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 60px;
`;
